// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Add_add__bf7Ch {\n  position: relative;\n}\n.Add_add__bf7Ch svg {\n  transition: all 0.2s;\n}\n.Add_add__bf7Ch svg:nth-child(2) {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  opacity: 0;\n}\n.Add_add__bf7Ch.Add_active__pK9me svg:first-child {\n  opacity: 0;\n}\n.Add_add__bf7Ch.Add_active__pK9me svg:nth-child(2) {\n  opacity: 1;\n}", "",{"version":3,"sources":["webpack://./src/screens/Home/Popular/Add/Add.module.sass"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAEI;EACI,oBAAA;AAAR;AACQ;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;AACZ;AAEY;EACI,UAAA;AAAhB;AACY;EACI,UAAA;AAChB","sourcesContent":["@import ../../../../styles/helpers\n\n.add\n    position: relative\n    svg\n        transition: all .2s\n        &:nth-child(2)\n            position: absolute\n            top: 50%\n            left: 50%\n            transform: translate(-50%,-50%)\n            opacity: 0\n    &.active\n        svg\n            &:first-child\n                opacity: 0\n            &:nth-child(2)\n                opacity: 1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add": "Add_add__bf7Ch",
	"active": "Add_active__pK9me"
};
export default ___CSS_LOADER_EXPORT___;
