import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Selection.module.sass";
import Icon from "../../../components/Icon";

const items = [
  {
    title: "홈킷 패키지",
    content: "1900원",
    counter: "다운로드 수 : 18",
    price: "플러그인",
    url: "/item",
    avatar: "/images/content/avatar-1.jpg",
    image: "/images/content/selection-pic-1.jpg",
    image2x: "/images/content/selection-pic-1@2x.jpg",
  },
  {
    title: "새로운 SSVF 플러그인",
    content: "2900원",
    price: "플러그인",
    url: "/item",
    avatar: "/images/content/avatar-4.jpg",
    image: "/images/content/selection-pic-2.jpg",
    image2x: "/images/content/selection-pic-2@2x.jpg",
  },
  {
    title: "최첨단 집을 위한 패키지",
    content: "2900원",
    price: "플러그인",
    url: "/item",
    avatar: "/images/content/avatar-3.jpg",
    image: "/images/content/selection-pic-1.jpg",
    image2x: "/images/content/selection-pic-1@2x.jpg",
  },
  {
    title: "게으른 나를 위한 잔소리 패키지",
    content: "2900원",
    price: "플러그인",
    url: "/item",
    avatar: "/images/content/avatar-4.jpg",
    image: "/images/content/selection-pic-3.jpg",
    image2x: "/images/content/selection-pic-3@2x.jpg",
  },
];

const users = [
  {
    name: "오현우",
    price: "<span>19</span> 팔로워",
    counter: "6",
    avatar: "/images/content/avatar-1.jpg",
  },
  {
    name: "정선우",
    price: "<span>29</span> 팔로워",
    counter: "2",
    avatar: "/images/content/avatar-2.jpg",
  },
  {
    name: "양우림",
    price: "<span>21</span> 팔로워",
    counter: "3",
    avatar: "/images/content/avatar-3.jpg",
  },
  {
    name: "지환",
    price: "<span>28</span> 팔로워",
    counter: "4",
    avatar: "/images/content/avatar-4.jpg",
  },
];

const Selection = () => {
  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            {items.map(
              (x, index) =>
                index === 0 && (
                  <Link className={styles.card} to={x.url} key={index}>
                    <div className={styles.preview}>
                      <img
                        srcSet={`${x.image2x} 2x`}
                        src={x.image}
                        alt="Selection"
                      />
                    </div>
                    <div className={styles.head}>
                      <div className={styles.line}>
                        <div className={styles.avatar}>
                          <img src={x.avatar} alt="Avatar" />
                        </div>
                        <div className={styles.description}>
                          <div className={styles.title}>{x.title}</div>
                          <div className={styles.counter}>{x.counter}</div>
                        </div>
                      </div>
                      <div className={styles.box}>
                        <div className={styles.content}>{x.content}</div>
                        <div className={styles.price}>{x.price}</div>
                      </div>
                    </div>
                  </Link>
                )
            )}
          </div>
          <div className={styles.col}>
            {items.map(
              (x, index) =>
                index > 0 && (
                  <Link className={styles.item} to={x.url} key={index}>
                    <div className={styles.preview}>
                      <img
                        srcSet={`${x.image2x} 2x`}
                        src={x.image}
                        alt="Selection"
                      />
                    </div>
                    <div className={styles.description}>
                      <div className={styles.title}>{x.title}</div>
                      <div className={styles.line}>
                        <div className={styles.avatar}>
                          <img src={x.avatar} alt="Avatar" />
                        </div>
                        <div className={styles.price}>{x.price}</div>
                        <div className={styles.content}>{x.content}</div>
                      </div>
                      <button
                        className={cn(
                          "button-stroke button-small",
                          styles.button
                        )}
                      >
                        상세보기
                      </button>
                    </div>
                  </Link>
                )
            )}
          </div>
        </div>
        <div className={styles.sidebar}>
          <div className={styles.info}>
            최근에 새로운 게시물을 업로드한 크리에이터{" "}
            <span className={styles.smile} role="img" aria-label="fire">
              🔥
            </span>
          </div>
          <div className={styles.list}>
            {users.map((x, index) => (
              <div className={styles.user} key={index}>
                <div className={styles.avatar}>
                  <img src={x.avatar} alt="Avatar" />
                  <div className={styles.number}>{x.counter}</div>
                </div>
                <div className={styles.description}>
                  <div className={styles.name}>{x.name}</div>
                  <div
                    className={styles.money}
                    dangerouslySetInnerHTML={{ __html: x.price }}
                  />
                </div>
              </div>
            ))}
          </div>
          <Link
            className={cn("button-stroke button-small", styles.button)}
            to="/search01"
          >
            <span>Discover more</span>
            <Icon name="arrow-next" size="10" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Selection;
