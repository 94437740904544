export const bids = [
  {
    title: "셈틀 홈 - 홈을 위한 패키지",
    price: "플러그인",
    highestBid: "1900원",
    counter: "3 in stock",
    bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
    image: "/images/content/card-pic-1.jpg",
    image2x: "/images/content/card-pic-1@2x.jpg",
    category: "blue",
    categoryText: "purchasing !",
    url: "/item",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-3.jpg",
      },
      {
        avatar: "/images/content/avatar-4.jpg",
      },
    ],
  },
  {
    title: "셈틀 오피스 - 오피스를 위한 패키지",
    price: "플러그인",
    highestBid: "1900원",
    counter: "3 in stock",
    bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
    image: "/images/content/card-pic-2.jpg",
    image2x: "/images/content/card-pic-2@2x.jpg",
    category: "green",
    categoryText: "purchasing !",
    url: "/item",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-4.jpg",
      },
    ],
  },
  {
    title: "셈틀 스튜디오 - 스튜디오를 위한 패키지",
    price: "플러그인",
    highestBid: "1900원",
    counter: "3 in stock",
    bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
    image: "/images/content/card-pic-3.jpg",
    image2x: "/images/content/card-pic-3@2x.jpg",
    category: "green",
    categoryText: "purchasing !",
    url: "/item",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-3.jpg",
      },
      {
        avatar: "/images/content/avatar-4.jpg",
      },
    ],
  },
  {
    title: "셈틀 뮤지엄 - 박물관을 위한 패키지",
    price: "플러그인",
    highestBid: "1900원",
    counter: "3 in stock",
    bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
    image: "/images/content/card-pic-4.jpg",
    image2x: "/images/content/card-pic-4@2x.jpg",
    category: "green",
    categoryText: "purchasing !",
    url: "/item",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-4.jpg",
      },
    ],
  },
];
