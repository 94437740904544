import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";

const items = [
  {
    title: "패키지 스토어",
    menu: [
      {
        title: "탐색하기",
        url: "/search01",
      },
      {
        title: "업로드하기",
        url: "/upload-variants",
      },
    ],
  },
  {
    title: "정보",
    menu: [
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "셈틀 스페이스란?",
        url: "/connect-wallet",
      },
    ],
  },
];

const Footers = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/ss_logo.svg"
                srcDark="/images/ss_logo_dark.svg"
                alt="Fitness Pro"
              />
            </Link>
            <div className={styles.info}>현실과 가상의 경계, 셈틀 스페이스</div>
            <div className={styles.version}>
              <div className={styles.details}>다크 모드</div>
              <Theme className="theme-big" />
            </div>
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            <div className={styles.category}>뉴스레터 받아보기</div>
            <div className={styles.text}>
              셈틀 스페이스 뉴스레터를 구독하여 업데이트 소식 등을 더 빠르게
              받아보세요.
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder="이메일을 입력하세요"
              type="email"
              name="email"
            />
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2024 셈틀 모든 권리 보유.
          </div>
          <div className={styles.note}>
            더 나은 서비스를 위해 쿠키를 수집합니다. <a href="/#">허용하기</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
