import React from "react";
import Hero from "./Hero";
import Selection from "./Selection";
import Popular from "./Popular";
import HotBid from "../../components/HotBid";
import Description from "./Description";

const Home = () => {
  return (
    <>
      <Description />

      <Popular />
      <HotBid classSection="section" />
    </>
  );
};

export default Home;
