// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageList_page__Cp8fA {\n  padding: 100px 0;\n}\n.PageList_page__Cp8fA p {\n  font-size: 16px;\n}\n.PageList_page__Cp8fA p:not(:last-child) {\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/screens/PageList/PageList.module.sass"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAEE;EACE,eAAA;AAAJ;AACI;EACE,mBAAA;AACN","sourcesContent":["@import ../../styles/helpers\n\n.page\n  padding: 100px 0\n  p\n    font-size: 16px\n    &:not(:last-child)\n      margin-bottom: 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "PageList_page__Cp8fA"
};
export default ___CSS_LOADER_EXPORT___;
